import React from 'react'
import {
  Wrapper,
  Grid,
  ValueProposition,
  ValuePropositionIcon,
  ValuePropositionText,
  CallToActionWrapper,
} from './style'
import SvgCheckIcon from '../svgs/icons/check_icon'
import ReadMoreLink from '../elements/link'
import { Link } from 'gatsby'
import CallToAction from '../elements/call_to_action'

const SectionValuePropositions = ({ valuePropositions, alignment, slug, callToAction }: any) => {
  return (
    <Wrapper>
      <Grid>
        {valuePropositions.map(
          (
            valueProposition: {
              icon: string
              text: string
              description: string
              link_text: string
              link_path: string
            },
            index: number,
          ) => (
            <ValueProposition className={alignment} key={index}>
              <ValuePropositionText>
                <div className="title">
                  <ValuePropositionIcon>
                    <SvgCheckIcon color="#06DE5D" />
                  </ValuePropositionIcon>
                  <span>{valueProposition.text}</span>
                </div>
                <div className={`${slug}-home-val-description`}>
                  <p>{valueProposition.description}</p>
                  {valueProposition.link_text && (
                    <ReadMoreLink>
                      <Link to={valueProposition.link_path} className={`color ${slug}`}>
                        {valueProposition.link_text} &rarr;
                      </Link>
                    </ReadMoreLink>
                  )}
                </div>
              </ValuePropositionText>
            </ValueProposition>
          ),
        )}
      </Grid>
      {callToAction && (
        <CallToActionWrapper>
          {callToAction.map((button: { link: string; text: string }, index: React.Key) => {
            return (
              <CallToAction key={index}>
                <Link to={button.link} className={`color ${slug}`}>
                  {button.text}
                </Link>
              </CallToAction>
            )
          })}
        </CallToActionWrapper>
      )}
    </Wrapper>
  )
}

export default SectionValuePropositions
