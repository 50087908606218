import styled from '@emotion/styled'
import tw from 'twin.macro'

export const Wrapper = styled.div`
  ${tw`
    max-w-7xl
    mx-auto
    z-10
  `}
`

export const CallToActionWrapper = styled.div`
  ${tw`
    mt-8
    flex
    flex-row
    items-center
    justify-center
    space-x-6
  `}
`

export const Grid = styled.div`
  ${tw`
    grid
    grid-cols-1
    gap-8
    lg:grid-cols-3
  `}
`

export const ValueProposition = styled.div`
  ${tw`
    flex 
    justify-start
    lg:justify-center
    items-start
    space-x-3
  `}
  &.align-left, 
  &.align-left > div {
    ${tw`
      lg:justify-start
    `}
  }
  &.align-left > div > div.title {
    ${tw`
      lg:justify-start
    `}
  }
`

export const ValuePropositionIcon = styled.div`
  ${tw`
    flex
    items-center
    justify-center
    h-5
    w-5
    rounded-full
  `}
  color: #1FC763;
  background-color: #e9ffc3;
  svg {
    ${tw`
      h-2
      w-3
    `}
  }
`

export const ValuePropositionText = styled.div`
  ${tw`
    flex 
    flex-col
    justify-center
  `}
  div.title {
    ${tw`
      flex 
      flex-row
      items-center
      lg:justify-center
      lg:items-start
      space-x-3
      mb-3
    `}
  }
  div.home-home-val-description {
    ${tw`
      pl-8
    `}
  }
  span {
    ${tw`
      text-lg
      lg:text-xl
      leading-6
      mb-1
      font-semibold
    `}
    color: #1FC763;
    white-space: pre-line;
  }
  p {
    ${tw`
      text-sm
      text-purple-300
      mb-3
    `}
  }
`
